/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConfirmDetailsContainer, StyledModal } from "./Styles";
import { withTranslation } from "react-i18next";
import { Border } from "../../Journeys/styles";
import { Modal } from "react-bootstrap";
import { ConfirmDetailsIcon, DatePickerIcon, UploadIcon, closeIcon } from "../../../utils/icons";
import { InputContainer } from "../../CreateEvent/styles";
import {
  // InputImage,
  MUICalendarWrapper,
  // RemoveImageV2,
  StyledInputV2,
  StyledLabel,
  UploadPhotoContainer,
} from "../../CreateEvent/style";
import { t } from "i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  checkImage,
  fetchApi,
  getOrientation,
  resetOrientation,
} from "../../../utils/methods";
import CommonButton from "../../common/CommonButton/CommonButton";
import dayjs from "dayjs";
import { UserInitiatives } from "../../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import moment from "moment";
import { imageErrorMessage } from "../../../utils/constants";

class ConfirmDetailsModal extends Component {
  constructor() {
    super();
    this.state = {
      imgSrc: "",
      imageName: "",
      selectedDate: dayjs(),
      disablePrevYear: dayjs(new Date(new Date().getFullYear(), 0, 1)),
      signature: "",
      loading:false
    };
  }

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 20MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  handleSignatureChange = (e) => {
    this.setState({ signature: e.target.value });
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  // convertDate(dateString) {
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');

  //   return `${year}-${month}-${day}`;
  // }

  // convertDDMMYYYDate(dateString) {
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');

  //   return `${month}-${day}-${year}`;
  // }

  handleConfirmDetails = () => {
    const {imgSrc, selectedDate, signature} = this.state;
    const {initiativeData} = this.props;
    const formattedDate = moment(selectedDate?.$d).format("YYYY-MM-DD");

    const confirmDetailsEntity = {
      // uid:2,
      initiative_id: initiativeData?.initiative_id,
      document: imgSrc,
      date: formattedDate,
      signature: signature
    }

    // window.console.log("confirmDetailsEntity: ", confirmDetailsEntity);
    this.PostEvent(confirmDetailsEntity);
    
  }

  PostEvent = async(payload)=>{
    this.setState({loading: true});
    try{
      const res = await fetchApi(UserInitiatives, "POST",payload);
      if(res?.data?.message){
        toast.error(res.data.message);
        this.setState({loading: false});
      }else{
        toast.success(res.data[0]);
        this.props.getUserCompanyItitiatives();
        this.props.showIsConfirmDetails();
        this.setState({loading: false});
      }

    }catch(error){
      toast.error(error);
      this.setState({loading: false});

    }
  }

  render() {
    const { showConfirmVisitModal, hideConfirmVisitModal, initiativeData, name } = this.props;
    const { disablePrevYear, selectedDate } = this.state;
    return (
      <StyledModal show={showConfirmVisitModal} width={"400px"} height={"532px"}>
        <div className={"modal-container"}>
          <div
            onClick={() => hideConfirmVisitModal()}
            style={{ margin: "14px 0px 0px -14px", cursor: "pointer" }}
          >
            {closeIcon()}
          </div>
          <div
            style={{
              color: "#005C87",
              marginTop: "0px",
              textDecoration: "none",
            }}
          >
            {"Confirm Visit"}
          </div>
        </div>
        <Border
          style={{
            background: "rgba(0, 92, 135, 0.10)",
            margin: "20px 0px 0px 0px",
          }}
        />
        <Modal.Body>
          <ConfirmDetailsContainer>
            <div className="sub-heading-text">
              <span>
                I {name}, have confirmed completion of my{" "}
                {initiativeData?.name} on {moment(selectedDate?.$d).format("MM-DD-YYYY")}.{" "}
              </span>
            </div>
            <div className="sub-body-container">
              <div>
                <InputContainer width="100%">
                  <StyledLabel color={"#005c87"}>
                    {t("Signature")}
                    {<span>*</span>}
                  </StyledLabel>
                  <StyledInputV2
                    placeholder={t("Enter signature")}
                    name="title"
                    onChange={(e) => this.handleSignatureChange(e)}
                    value={this.state.signature}
                    maxLength="100"
                    style={{ background: "white", fontFamily: "MonteCarlo", fontSize: '24px' }}
                    pColor={"#649bb3"}
                    color="#005c87"
                    border={"1px solid rgba(0, 122, 177, 0.30)"}
                  />
                </InputContainer>
                <Border
                  style={{
                    background: "rgba(0, 92, 135, 0.10)",
                    margin: "20px 0px",
                  }}
                />
              </div>
              <div>
                <InputContainer width="100%">
                  <StyledLabel style={{ marginTop: "0px" }} color={"#005c87"}>
                    {t("Date")}
                    {<span>*</span>}
                  </StyledLabel>
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper>
                        <DatePicker
                          label="Select Date"
                          sx={{
                            width: "100%",
                            color: "#005C87",
                            borderColor: "#005C87",
                            ".MuiButtonBase-root > svg": {
                              outline: "none",
                              width: "24px",
                              height: "23.49px",
                            },
                          }}
                          onChange={(value) => {
                            this.handleDateChange(value);
                          }}
                          value={selectedDate}
                          minDate={dayjs(initiativeData?.start_date) || disablePrevYear}
                          // maxDate={dayjs(initiativeData?.date)}
                          disableFuture
                          slots={{ openPickerIcon: DatePickerIcon }}
                          slotProps={{
                            textField: {readOnly: true,},
                          }}
                        />
                      </MUICalendarWrapper>
                    </LocalizationProvider>
                  </React.Fragment>
                </InputContainer>
                <Border
                  style={{
                    background: "rgba(0, 92, 135, 0.10)",
                    margin: "20px 0px",
                  }}
                />
              </div>
            </div>
            {initiativeData?.is_document_required === 1 && <div className="upload-doc-container">
              <UploadPhotoContainer>
                <div>
                  {this.state.imgSrc ? (
                    <span className="sub-heading-text">{this.state.imageName} uploaded!</span>
                  ) : (
                    <div className="middle-section">
                      {/* <div className="title">{t("Upload Document")}</div> */}
                      <label htmlFor="event-upload-file" className="custom-upload-button">
                        {UploadIcon()}
                        <span style={{ fontFamily: 'Rubik-Medium', lineHeight: '20px' }}>{t("Upload Document")}</span>
                      </label>
                      <input
                        id="event-upload-file"
                        type="file"
                        name="user"
                        accept=".jpeg, .png, .jpg"
                        multiple={false}
                        onChange={(e) => this.onChange(e)}
                        onClick={(e) => e.target.files[0] && this.onChange(e)}
                      />
                    </div>
                  )}
                </div>
              </UploadPhotoContainer>
            </div>}
            <div className="confirm-details-container">
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.handleConfirmDetails()}
                disabled={!this.state.signature || initiativeData?.is_document_required === 1 && !this.state.imgSrc || !this.state.selectedDate || this.state.loading}
                title={t("Confirm Details")}
                styles={{color:"#007AB1"}}
                style={{ width: '100%' }}
                nextIcon={ConfirmDetailsIcon()}
              />
            </div>
          </ConfirmDetailsContainer>
        </Modal.Body>
      </StyledModal>
    );
  }
}

ConfirmDetailsModal.propTypes = {
  showConfirmVisitModal: PropTypes.bool,
  hideConfirmVisitModal: PropTypes.func,
  showIsConfirmDetails: PropTypes.func,
  hideIsConfirmDetails: PropTypes.func,
  initiativeData: PropTypes.any,
  name: PropTypes.string,
  getUserCompanyItitiatives: PropTypes.func
};

export default withTranslation()(ConfirmDetailsModal);
